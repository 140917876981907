<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col  cols="12" sm="12" md="12">


<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <div>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=949
     style='width:711.85pt;border-collapse:collapse;mso-yfti-tbllook:1184;
     mso-padding-alt:0in 0in 0in 0in'>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:3.55pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:3.55pt'></td>
      <td width=1 style='width:.75pt;padding:0in 0in 0in 0in;height:3.55pt;
      min-width: 0.26mm'></td>
      <td width=419 style='width:314.3pt;padding:0in 0in 0in 0in;height:3.55pt;
      min-width: 110.86mm'></td>
      <td width=240 style='width:2.5in;padding:0in 0in 0in 0in;height:3.55pt;
      min-width: 63.50mm'></td>
      <td width=289 style='width:216.75pt;padding:0in 0in 0in 0in;height:3.55pt;
      min-width: 76.46mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;height:7.9pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:7.9pt'></td>
      <td width=1 valign=top style='width:.75pt;padding:0in 0in 0in 0in;
      height:7.9pt;min-width: 0.26mm'></td>
      <td width=419 rowspan=2 valign=top style='width:314.3pt;padding:0in 0in 0in 0in;
      height:7.9pt;min-width: 110.86mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:25.9pt'>
        <td width=419 valign=top style='width:314.3pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:25.9pt;min-width: 110.86mm'>
        <p class=MsoNormal><b><span style='font-size:20.0pt;mso-fareast-font-family:
        "Times New Roman";color:black'>Workload Report - Summary<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=529 colspan=2 valign=top style='width:396.8pt;padding:0in 0in 0in 0in;
      height:7.9pt;min-width: 139.96mm'></td>
     </tr>
     <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes;height:.25in'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:.25in'></td>
      <td width=1 valign=top style='width:.75pt;padding:0in 0in 0in 0in;
      height:.25in;min-width: 0.26mm'></td>
      <td width=240 valign=top style='width:2.5in;padding:0in 0in 0in 0in;
      height:.25in;min-width: 63.50mm'></td>
      <td width=289 valign=top style='width:216.75pt;padding:0in 0in 0in 0in;
      height:.25in;min-width: 76.46mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=288 valign=top style='width:216.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 76.20mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Run Date: 2020-11-13<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </div>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1'>
    <td style='padding:0in 0in 0in 0in;min-width: 251.09mm'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'
     cols=1>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=949 style='width:711.85pt;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0in 0in 0in 0in;min-width: 251.09mm'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:6.0pt'>
        <td width=949 style='width:711.85pt;padding:0in 0in 0in 0in;height:
        6.0pt'></td>
       </tr>
       <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
        <td width=949 valign=top style='width:711.85pt;padding:0in 0in 0in 0in;
        min-width: 251.09mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=949 style='width:711.85pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 251.09mm' cols=4>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=361 style='width:270.75pt;padding:0in 0in 0in 0in;
          min-width: 95.51mm'></td>
          <td width=86 style='width:64.5pt;padding:0in 0in 0in 0in;min-width: 22.75mm'></td>
          <td width=100 style='width:75.0pt;padding:0in 0in 0in 0in;min-width: 26.46mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:35.25pt'>
          <td width=402 valign=top style='width:301.55pt;border:solid lightgrey 1.0pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:35.25pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:11.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Officer<br>
          Request Type<o:p></o:p></span></b></p>
          </td>
          <td width=361 valign=top style='width:270.75pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:35.25pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:35.25pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:11.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Total Requests<o:p></o:p></span></b></p>
          </td>
          <td width=100 valign=top style='width:75.0pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:35.25pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:11.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Pages Reviewed<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:15.1pt'>
          <td width=402 valign=top style='width:301.55pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;background:
          lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=361 valign=top style='width:270.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=100 valign=top style='width:75.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:3;height:15.1pt'>
          <td width=402 valign=top style='width:301.55pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;background:
          gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;font-family:"Tahoma",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Access Requests (A)<o:p></o:p></span></b></p>
          </td>
          <td width=361 valign=top style='width:270.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Tahoma",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2<o:p></o:p></span></b></p>
          </td>
          <td width=100 valign=top style='width:75.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Tahoma",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>269<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:4;height:15.1pt'>
          <td width=402 valign=top style='width:301.55pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;background:
          gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;font-family:"Tahoma",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Projects (PR) <o:p></o:p></span></b></p>
          </td>
          <td width=361 valign=top style='width:270.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Tahoma",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2<o:p></o:p></span></b></p>
          </td>
          <td width=100 valign=top style='width:75.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Tahoma",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1012<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:5;height:.25in'>
          <td width=402 valign=top style='width:301.55pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=361 valign=top style='width:270.75pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><i><span style='font-size:10.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Total<o:p></o:p></span></i></b></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Tahoma",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>4<o:p></o:p></span></b></p>
          </td>
          <td width=100 valign=top style='width:75.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Tahoma",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1281<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:6;height:15.1pt'>
          <td width=402 valign=top style='width:301.55pt;border:solid lightgrey 1.0pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;font-family:"Tahoma",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Gagliardi, Dan<o:p></o:p></span></b></p>
          </td>
          <td width=361 valign=top style='width:270.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=100 valign=top style='width:75.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:7;height:15.1pt'>
          <td width=402 valign=top style='width:301.55pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;background:
          gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;font-family:"Tahoma",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Access Requests (A)<o:p></o:p></span></b></p>
          </td>
          <td width=361 valign=top style='width:270.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Tahoma",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>3<o:p></o:p></span></b></p>
          </td>
          <td width=100 valign=top style='width:75.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Tahoma",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>45<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:8;height:.25in'>
          <td width=402 valign=top style='width:301.55pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=361 valign=top style='width:270.75pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><i><span style='font-size:10.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Gagliardi, Dan Total<o:p></o:p></span></i></b></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Tahoma",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>3<o:p></o:p></span></b></p>
          </td>
          <td width=100 valign=top style='width:75.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Tahoma",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>45<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:9;height:15.1pt'>
          <td width=402 valign=top style='width:301.55pt;border:solid lightgrey 1.0pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;font-family:"Tahoma",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Lyon, Melissa<o:p></o:p></span></b></p>
          </td>
          <td width=361 valign=top style='width:270.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=100 valign=top style='width:75.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:10;height:15.1pt'>
          <td width=402 valign=top style='width:301.55pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;background:
          gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;font-family:"Tahoma",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Access Requests (A)<o:p></o:p></span></b></p>
          </td>
          <td width=361 valign=top style='width:270.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Tahoma",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>8<o:p></o:p></span></b></p>
          </td>
          <td width=100 valign=top style='width:75.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Tahoma",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1912<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:11;mso-yfti-lastrow:yes;height:.25in'>
          <td width=402 valign=top style='width:301.55pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=361 valign=top style='width:270.75pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><i><span style='font-size:10.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Lyon, Melissa Total<o:p></o:p></span></i></b></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Tahoma",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>8<o:p></o:p></span></b></p>
          </td>
          <td width=100 valign=top style='width:75.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;font-family:"Tahoma",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1912<o:p></o:p></span></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=949
     style='width:711.85pt;border-collapse:collapse;mso-yfti-tbllook:1184;
     mso-padding-alt:0in 0in 0in 0in' cols=5>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:2.65pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:2.65pt'></td>
      <td width=1 style='width:.75pt;padding:0in 0in 0in 0in;height:2.65pt;
      min-width: 0.26mm'></td>
      <td width=263 style='width:197.3pt;padding:0in 0in 0in 0in;height:2.65pt;
      min-width: 69.59mm'></td>
      <td width=422 style='width:316.15pt;padding:0in 0in 0in 0in;height:2.65pt;
      min-width: 111.52mm'></td>
      <td width=263 style='width:197.3pt;padding:0in 0in 0in 0in;height:2.65pt;
      min-width: 69.59mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;height:2.4pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:2.4pt'></td>
      <td width=686 colspan=3 valign=top style='width:514.2pt;padding:0in 0in 0in 0in;
      height:2.4pt;min-width: 181.37mm'></td>
      <td width=263 rowspan=2 valign=top style='width:197.3pt;padding:0in 0in 0in 0in;
      height:2.4pt;min-width: 69.59mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:20.25pt'>
        <td width=263 valign=top style='width:197.3pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:20.25pt;min-width: 69.59mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Page 1 of 1<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:2;height:17.9pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:17.9pt'></td>
      <td width=1 valign=top style='width:.75pt;padding:0in 0in 0in 0in;
      height:17.9pt;min-width: 0.26mm'></td>
      <td width=263 rowspan=2 valign=top style='width:197.3pt;padding:0in 0in 0in 0in;
      height:17.9pt;min-width: 69.59mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:20.25pt'>
        <td width=263 valign=top style='width:197.3pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:20.25pt;min-width: 69.59mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Axcess-1<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=422 valign=top style='width:316.15pt;padding:0in 0in 0in 0in;
      height:17.9pt;min-width: 111.52mm'></td>
     </tr>
     <tr style='mso-yfti-irow:3;mso-yfti-lastrow:yes;height:4.15pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:4.15pt'></td>
      <td width=1 style='width:.75pt;padding:0in 0in 0in 0in;height:4.15pt;
      min-width: 0.26mm'></td>
      <td width=685 colspan=2 style='width:513.45pt;padding:0in 0in 0in 0in;
      height:4.15pt;min-width: 181.11mm'></td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>

<p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'><o:p>&nbsp;</o:p></span></p>



        


        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

//import { mapGetters, mapState} from "vuex";
import axcessApi from "@/plugins/axcessApi";
export default {
  props:['organisation','dateFrom','dateTo','reportId'],
  created(){
    this.generateReport();
  },
  methods: {
    generateReport() {
      let data = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        reportId: this.reportId

      }
      const response = axcessApi.postRequest("/report/tbs",data);
      response.then(output => {
        console.log(output);
      })
    },
    selected() {
    }
  }
};
</script>