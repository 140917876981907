
<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-select v-model="reqType"   @change="selected" :items="reqTypes" item-text="label" item-value="id" label="Choose workload Report"></v-select>
          </v-col>
        </v-row>


     <v-row v-if="reqType==0">
       
        <v-col cols="12" sm="6" md="6">
            <v-select v-model="request_type" :items="request_types" item-text="description_en" item-value="id" label="Request Type"></v-select>
          </v-col>
           
        <v-col cols="12" sm="6" md="6">
            <v-select v-model="officer" :items="officers" item-text="name" item-value="id" label="Officer"></v-select>
        </v-col>
        </v-row>

        <v-row v-if="reqType==1">
          <v-col cols="12" sm="6" md="6">
            <DatePicker v-model="dateFrom" label="From"></DatePicker>
          </v-col>

          
          <v-col cols="12" sm="6" md="6">
            <DatePicker v-model="dateFrom" label="To"></DatePicker>
          </v-col>
     
          <v-col cols="12" sm="6" md="6">
            <v-select v-model="Manager" :items="Managers" item-text="name" item-value="name" label="Manager"></v-select>
          </v-col>

            <v-col cols="12" sm="6" md="6">
            <v-select v-model="request_type" :items="request_types" item-text="description_en" item-value="id" label="Request Type"></v-select>
          </v-col>
        </v-row>


              <v-row v-if="reqType==2">

             <v-col cols="12" sm="12" md="12">
            <v-select v-model="request_type" :items="request_types" item-text="description_en" item-value="id" label="Request Type"></v-select>
          </v-col>

        </v-row>




        <v-btn v-if="reqType!= -1" color="primary" dark class="mb-2" @click="generateReport">{{$t('View Report')}}</v-btn>
        <v-btn v-if="reqType!= -1" color="primary" dark class="mb-2" @click="closeReport">{{$t('Close Report')}}</v-btn>


      </v-card-text>
    </v-card>
    <workloaddetailed v-if="reqType == 0 && flag==1" :reportId="reqType" :dateFrom="dateFrom" :dateTo="dateTo" :organisation="this.$store.getters.userInfo"></workloaddetailed>
    <workloadmanagerview v-if="reqType == 1 && flag==1" :reportId="reqType" :dateFrom="dateFrom" :dateTo="dateTo" :organisation="this.$store.getters.userInfo"></workloadmanagerview>
    <workloadsummary v-if="reqType == 2 && flag==1" :reportId="reqType" :dateFrom="dateFrom" :dateTo="dateTo" :organisation="this.$store.getters.userInfo"></workloadsummary>
  
    <div v-else>
      <v-progress-circular
          v-if="flag==1"
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>

import DatePicker from "../components/DatePicker";
import workloaddetailed from "../components/report/workloaddetailed";
import Workloadmanagerview from "../components/report/workloadmanagerview";
import workloadsummary from "../components/report/workloadsummary";
import { mapState,mapActions } from "vuex";

export default {
  created () {
  },
  components: {
    DatePicker,
    workloaddetailed,
    Workloadmanagerview,
    workloadsummary

  },
  data(){
    return {
      flag:0,
      reqType:"-1",
      reqTypes: [
     { id:'0',label:"Workload Report Detailed"},
     { id:'1',label:"Workload Report Manager View"},
     { id:'2',label:"Workload Report Summary"}
      ],
      dateFrom: new Date().toISOString().substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      displays:[
        { id:'0',name:"New disclosures Active"},
        { id:'1',name:"Disclosures New Active"}

      ],
      display:"0",

    };
  },
  methods:{
      ...mapActions(["retriveTypes"]),
    selected(){

    },
    generateReport(){
      this.flag = 1;
  
    },
    closeReport(){
      this.flag = 0;
    }
  },
       computed: {

   ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration'
    }),

      ...mapState({
      request_types: "types",
    }),
   },
      mounted () {
         this.retriveTypes();
        
           // console.log("heyy",this.configurations);
    },
  
};

</script>


<style lang="scss" scoped>
table, th, td{
  width:1px !important;
 font-size: 1px !important;
 
}
</style>
