<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col  cols="12" sm="12" md="12">

        

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <div>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1440 style='width:15.0in;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in'>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:3.55pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:3.55pt'></td>
      <td width=1 style='width:.75pt;padding:0in 0in 0in 0in;height:3.55pt;
      min-width: 0.26mm'></td>
      <td width=1440 style='width:15.0in;padding:0in 0in 0in 0in;height:3.55pt;
      min-width: 473.34mm'></td>
      <td width=288 style='width:216.05pt;padding:0in 0in 0in 0in;height:3.55pt;
      min-width: 76.20mm'></td>
      <td width=2 style='width:1.5pt;padding:0in 0in 0in 0in;height:3.55pt;
      min-width: 0.53mm'></td>
      <td width=16 style='width:12.0pt;padding:0in 0in 0in 0in;height:3.55pt;
      min-width: 4.23mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;height:25.9pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:25.9pt'></td>
      <td width=1 valign=top style='width:.75pt;padding:0in 0in 0in 0in;
      height:25.9pt;min-width: 0.26mm'></td>
      <td width=1440 colspan=4 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
      height:25.9pt;min-width: 554.30mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:25.9pt'>
        <td>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:20.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Detailed Workload Report - Manager's View<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:2;height:5.05pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:5.05pt'></td>
      <td width=1440 colspan=5 style='width:15.0in;padding:0in 0in 0in 0in;
      height:5.05pt;min-width: 554.57mm'></td>
     </tr>
     <tr style='mso-yfti-irow:3;height:18.95pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:18.95pt'></td>
      <td width=1 valign=top style='width:.75pt;padding:0in 0in 0in 0in;
      height:18.95pt;min-width: 0.26mm'></td>
      <td width=1440 colspan=3 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
      height:18.95pt;min-width: 550.07mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:18.95pt'>
        <td>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Request Type: All Request Types; Approver: All Approvers;
        Due: From 4/1/2011 To 11/13/2020<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=16 valign=top style='width:12.0pt;padding:0in 0in 0in 0in;
      height:18.95pt;min-width: 4.23mm'></td>
     </tr>
     <tr style='mso-yfti-irow:4;mso-yfti-lastrow:yes;height:25.5pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:25.5pt'></td>
      <td width=1440 colspan=2 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
      height:25.5pt;min-width: 473.60mm'></td>
      <td width=288 valign=top style='width:216.05pt;padding:0in 0in 0in 0in;
      height:25.5pt;min-width: 76.20mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=288 valign=top style='width:216.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 76.20mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Run Date: 2020-11-13<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=18 colspan=2 valign=top style='width:13.5pt;padding:0in 0in 0in 0in;
      height:25.5pt;min-width: 4.76mm'></td>
     </tr>
    </table>
    </div>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'
     cols=3>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=1440 style='width:15.0in;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:26.5pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:26.5pt'></td>
        <td width=2 style='width:1.5pt;padding:0in 0in 0in 0in;height:26.5pt;
        min-width: 0.53mm'></td>
        <td width=1440 style='width:15.0in;padding:0in 0in 0in 0in;height:26.5pt;
        min-width: 554.04mm'></td>
       </tr>
       <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=2 valign=top style='width:1.5pt;padding:0in 0in 0in 0in;
        min-width: 0.53mm'></td>
        <td width=1440 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
        min-width: 554.04mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=682 style='width:511.6pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 180.45mm' cols=4>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=193 style='width:144.75pt;padding:0in 0in 0in 0in;
          min-width: 51.06mm'></td>
          <td width=86 style='width:64.5pt;padding:0in 0in 0in 0in;min-width: 22.75mm'></td>
          <td width=91 style='width:68.25pt;padding:0in 0in 0in 0in;min-width: 24.08mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:.25in'>
          <td width=682 colspan=4 valign=top style='width:511.6pt;border:solid lightgrey 1.0pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='mso-fareast-font-family:"Times New Roman";color:white'>Workload
          Summary for All Officers<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:30.0pt'>
          <td width=312 valign=top style='width:234.05pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;background:
          steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:30.0pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Officer<br>
          Request Type<o:p></o:p></span></b></p>
          </td>
          <td width=193 valign=top style='width:144.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:30.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:30.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Total Requests<o:p></o:p></span></b></p>
          </td>
          <td width=91 valign=top style='width:68.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:30.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Pages Reviewed<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:3;height:15.1pt'>
          <td width=312 valign=top style='width:234.05pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;background:
          whitesmoke;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Gagliardi, Dan<o:p></o:p></span></b></p>
          </td>
          <td width=193 valign=top style='width:144.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:whitesmoke;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:whitesmoke;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=91 valign=top style='width:68.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:whitesmoke;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:4;height:15.1pt'>
          <td width=312 valign=top style='width:234.05pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Access Requests (A)<o:p></o:p></span></b></p>
          </td>
          <td width=193 valign=top style='width:144.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2<o:p></o:p></span></b></p>
          </td>
          <td width=91 valign=top style='width:68.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>7<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:5;height:.25in'>
          <td width=312 valign=top style='width:234.05pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=193 valign=top style='width:144.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><i><span style='font-size:10.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Gagliardi, Dan Total<o:p></o:p></span></i></b></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2<o:p></o:p></span></b></p>
          </td>
          <td width=91 valign=top style='width:68.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>7<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:6;height:15.1pt'>
          <td width=312 valign=top style='width:234.05pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;background:
          whitesmoke;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Lyon, Melissa<o:p></o:p></span></b></p>
          </td>
          <td width=193 valign=top style='width:144.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:whitesmoke;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:whitesmoke;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=91 valign=top style='width:68.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:whitesmoke;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:7;height:15.1pt'>
          <td width=312 valign=top style='width:234.05pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Access Requests (A)<o:p></o:p></span></b></p>
          </td>
          <td width=193 valign=top style='width:144.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>7<o:p></o:p></span></b></p>
          </td>
          <td width=91 valign=top style='width:68.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1919<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:8;height:.25in'>
          <td width=312 valign=top style='width:234.05pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=193 valign=top style='width:144.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><i><span style='font-size:10.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Lyon, Melissa Total<o:p></o:p></span></i></b></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>7<o:p></o:p></span></b></p>
          </td>
          <td width=91 valign=top style='width:68.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1919<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:9;mso-yfti-lastrow:yes;height:.25in'>
          <td width=312 valign=top style='width:234.05pt;border:none;
          mso-border-top-alt:solid black 2.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=193 valign=top style='width:144.75pt;border:none;
          mso-border-top-alt:solid black 2.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><i><span style='font-size:10.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Grand Total<o:p></o:p></span></i></b></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border:none;mso-border-top-alt:
          solid black 2.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>9<o:p></o:p></span></b></p>
          </td>
          <td width=91 valign=top style='width:68.25pt;border:none;mso-border-top-alt:
          solid black 2.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1926<o:p></o:p></span></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1440 style='width:15.0in;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in' cols=5>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:7.9pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:7.9pt'></td>
      <td width=3 style='width:2.25pt;padding:0in 0in 0in 0in;height:7.9pt;
      min-width: 0.79mm'></td>
      <td width=263 style='width:197.3pt;padding:0in 0in 0in 0in;height:7.9pt;
      min-width: 69.59mm'></td>
      <td width=1440 style='width:15.0in;padding:0in 0in 0in 0in;height:7.9pt;
      min-width: 414.21mm'></td>
      <td width=265 style='width:198.4pt;padding:0in 0in 0in 0in;height:7.9pt;
      min-width: 69.98mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
      <td width=1440 colspan=3 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
      min-width: 484.58mm'></td>
      <td width=265 rowspan=2 valign=top style='width:198.4pt;padding:0in 0in 0in 0in;
      min-width: 69.98mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:20.25pt'>
        <td width=263 valign=top style='width:197.3pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:20.25pt;min-width: 69.59mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Page 1 of 2<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes;height:20.25pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:20.25pt'></td>
      <td width=3 valign=top style='width:2.25pt;padding:0in 0in 0in 0in;
      height:20.25pt;min-width: 0.79mm'></td>
      <td width=263 valign=top style='width:197.3pt;padding:0in 0in 0in 0in;
      height:20.25pt;min-width: 69.59mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:20.25pt'>
        <td width=263 valign=top style='width:197.3pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:20.25pt;min-width: 69.59mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Axcess-1<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=1440 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
      height:20.25pt;min-width: 414.21mm'></td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>

<div>

<div class=MsoNormal align=center style='text-align:center'><span
style='mso-fareast-font-family:"Times New Roman"'>

<hr size=2 width="100%" align=center>

</span></div>

</div>

<div>

<div>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'
 cols=6>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <div>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1440 style='width:15.0in;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in'>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:3.55pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:3.55pt'></td>
      <td width=1 style='width:.75pt;padding:0in 0in 0in 0in;height:3.55pt;
      min-width: 0.26mm'></td>
      <td width=1440 style='width:15.0in;padding:0in 0in 0in 0in;height:3.55pt;
      min-width: 473.34mm'></td>
      <td width=288 style='width:216.05pt;padding:0in 0in 0in 0in;height:3.55pt;
      min-width: 76.20mm'></td>
      <td width=2 style='width:1.5pt;padding:0in 0in 0in 0in;height:3.55pt;
      min-width: 0.53mm'></td>
      <td width=16 style='width:12.0pt;padding:0in 0in 0in 0in;height:3.55pt;
      min-width: 4.23mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;height:25.9pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:25.9pt'></td>
      <td width=1 valign=top style='width:.75pt;padding:0in 0in 0in 0in;
      height:25.9pt;min-width: 0.26mm'></td>
      <td width=1440 colspan=4 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
      height:25.9pt;min-width: 554.30mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:25.9pt'>
        <td>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:20.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Detailed Workload Report - Manager's View<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:2;height:5.05pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:5.05pt'></td>
      <td width=1440 colspan=5 style='width:15.0in;padding:0in 0in 0in 0in;
      height:5.05pt;min-width: 554.57mm'></td>
     </tr>
     <tr style='mso-yfti-irow:3;height:18.95pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:18.95pt'></td>
      <td width=1 valign=top style='width:.75pt;padding:0in 0in 0in 0in;
      height:18.95pt;min-width: 0.26mm'></td>
      <td width=1440 colspan=3 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
      height:18.95pt;min-width: 550.07mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:18.95pt'>
        <td>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Request Type: All Request Types; Approver: All Approvers;
        Due: From 4/1/2011 To 11/13/2020<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=16 valign=top style='width:12.0pt;padding:0in 0in 0in 0in;
      height:18.95pt;min-width: 4.23mm'></td>
     </tr>
     <tr style='mso-yfti-irow:4;mso-yfti-lastrow:yes;height:25.5pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:25.5pt'></td>
      <td width=1440 colspan=2 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
      height:25.5pt;min-width: 473.60mm'></td>
      <td width=288 valign=top style='width:216.05pt;padding:0in 0in 0in 0in;
      height:25.5pt;min-width: 76.20mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=288 valign=top style='width:216.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 76.20mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Run Date: 2020-11-13<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=18 colspan=2 valign=top style='width:13.5pt;padding:0in 0in 0in 0in;
      height:25.5pt;min-width: 4.76mm'></td>
     </tr>
    </table>
    </div>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'
     cols=3>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=1440 style='width:15.0in;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:16.0pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:16.0pt'></td>
        <td width=3 style='width:2.25pt;padding:0in 0in 0in 0in;height:16.0pt;
        min-width: 0.79mm'></td>
        <td width=1440 style='width:15.0in;padding:0in 0in 0in 0in;height:16.0pt;
        min-width: 553.77mm'></td>
       </tr>
       <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=3 valign=top style='width:2.25pt;padding:0in 0in 0in 0in;
        min-width: 0.79mm'></td>
        <td width=1440 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
        min-width: 553.77mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=1440 style='width:15.0in;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 553.77mm' cols=21>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=79 style='width:59.25pt;padding:0in 0in 0in 0in;min-width: 20.90mm'></td>
          <td width=78 style='width:58.5pt;padding:0in 0in 0in 0in;min-width: 20.64mm'></td>
          <td width=79 style='width:59.25pt;padding:0in 0in 0in 0in;min-width: 20.90mm'></td>
          <td width=321 style='width:240.8pt;padding:0in 0in 0in 0in;
          min-width: 84.93mm'></td>
          <td width=144 style='width:1.5in;padding:0in 0in 0in 0in;min-width: 38.10mm'></td>
          <td width=76 style='width:57.0pt;padding:0in 0in 0in 0in;min-width: 20.11mm'></td>
          <td width=76 style='width:57.0pt;padding:0in 0in 0in 0in;min-width: 20.11mm'></td>
          <td width=76 style='width:57.0pt;padding:0in 0in 0in 0in;min-width: 20.11mm'></td>
          <td width=71 style='width:53.25pt;padding:0in 0in 0in 0in;min-width: 18.79mm'></td>
          <td width=83 style='width:62.25pt;padding:0in 0in 0in 0in;min-width: 21.96mm'></td>
          <td width=66 style='width:49.5pt;padding:0in 0in 0in 0in;min-width: 17.46mm'></td>
          <td width=71 style='width:53.25pt;padding:0in 0in 0in 0in;min-width: 18.79mm'></td>
          <td width=92 style='width:69.0pt;padding:0in 0in 0in 0in;min-width: 24.34mm'></td>
          <td width=83 style='width:62.25pt;padding:0in 0in 0in 0in;min-width: 21.96mm'></td>
          <td width=78 style='width:58.5pt;padding:0in 0in 0in 0in;min-width: 20.64mm'></td>
          <td width=81 style='width:60.75pt;padding:0in 0in 0in 0in;min-width: 21.43mm'></td>
          <td width=123 style='width:92.25pt;padding:0in 0in 0in 0in;
          min-width: 32.54mm'></td>
          <td width=90 style='width:67.5pt;padding:0in 0in 0in 0in;min-width: 23.81mm'></td>
          <td width=90 style='width:67.5pt;padding:0in 0in 0in 0in;min-width: 23.81mm'></td>
          <td width=125 style='width:93.75pt;padding:0in 0in 0in 0in;
          min-width: 33.07mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:.25in'>
          <td width=111 valign=top style='width:83.25pt;border:solid lightgrey 1.0pt;
          border-bottom:none;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=296 colspan=3 valign=top style='width:222.05pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-alt:solid lightgrey .25pt;background:steelblue;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Last Event<o:p></o:p></span></b></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=right style='text-align:right'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=457 colspan=5 valign=top style='width:342.8pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-alt:solid lightgrey .25pt;background:steelblue;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Outstanding Actions<o:p></o:p></span></b></p>
          </td>
          <td width=305 colspan=3 valign=top style='width:228.8pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-alt:solid lightgrey .25pt;background:steelblue;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Last Workplan Event<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:45.0pt'>
          <td width=111 valign=top style='width:83.25pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-left-alt:solid lightgrey .25pt;mso-border-bottom-alt:
          solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Officer<br>
          Request Type<br>
          Request #<o:p></o:p></span></b></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-bottom-alt:solid lightgrey .25pt;
          mso-border-right-alt:solid lightgrey .25pt;background:steelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Date Received<o:p></o:p></span></b></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-bottom-alt:solid lightgrey .25pt;
          mso-border-right-alt:solid lightgrey .25pt;background:steelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Date Due<o:p></o:p></span></b></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-bottom-alt:solid lightgrey .25pt;
          mso-border-right-alt:solid lightgrey .25pt;background:steelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Requestor<o:p></o:p></span></b></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-bottom-alt:solid lightgrey .25pt;
          mso-border-right-alt:solid lightgrey .25pt;background:steelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Summary Text<o:p></o:p></span></b></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-alt:solid lightgrey .25pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Event/<br>
          Note<o:p></o:p></span></b></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-alt:solid lightgrey .25pt;background:steelblue;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Start Date<o:p></o:p></span></b></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-alt:solid lightgrey .25pt;background:steelblue;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Alarm Date<o:p></o:p></span></b></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-bottom-alt:solid lightgrey .25pt;
          mso-border-right-alt:solid lightgrey .25pt;background:steelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Coming Due in 10 days<o:p></o:p></span></b></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-bottom-alt:solid lightgrey .25pt;
          mso-border-right-alt:solid lightgrey .25pt;background:steelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Complaints<o:p></o:p></span></b></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-bottom-alt:solid lightgrey .25pt;
          mso-border-right-alt:solid lightgrey .25pt;background:steelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Federal Court<o:p></o:p></span></b></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-bottom-alt:solid lightgrey .25pt;
          mso-border-right-alt:solid lightgrey .25pt;background:steelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Total Requests<o:p></o:p></span></b></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-bottom-alt:solid lightgrey .25pt;
          mso-border-right-alt:solid lightgrey .25pt;background:steelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Pages Reviewed<o:p></o:p></span></b></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-alt:solid lightgrey .25pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Event<o:p></o:p></span></b></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-top-alt:.25pt;mso-border-left-alt:.25pt;mso-border-bottom-alt:
          1.0pt;mso-border-right-alt:1.0pt;mso-border-color-alt:lightgrey;
          mso-border-style-alt:solid;background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:45.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Start Date<o:p></o:p></span></b></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Alarm Date<o:p></o:p></span></b></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-top-alt:.25pt;mso-border-left-alt:1.0pt;mso-border-bottom-alt:
          1.0pt;mso-border-right-alt:.25pt;mso-border-color-alt:lightgrey;
          mso-border-style-alt:solid;background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:45.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Complete Date<o:p></o:p></span></b></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-alt:solid lightgrey .25pt;background:steelblue;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Note<o:p></o:p></span></b></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;background:steelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Start Date<o:p></o:p></span></b></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Alarm Date<o:p></o:p></span></b></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-alt:solid lightgrey .25pt;
          background:steelblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:45.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:white'>Note<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:3;height:15.1pt'>
          <td width=347 colspan=4 valign=top style='width:260.3pt;border:solid lightgrey 1.0pt;
          border-top:none;mso-border-top-alt:solid lightgrey 1.0pt;mso-border-alt:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Gagliardi, Dan<o:p></o:p></span></b></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey 1.0pt;mso-border-alt:solid lightgrey 1.0pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:lightblue;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:4;height:15.1pt'>
          <td width=347 colspan=4 valign=top style='width:260.3pt;border:none;
          border-bottom:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Access Requests (A)<o:p></o:p></span></b></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          mso-border-right-alt:solid lightgrey .25pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2<o:p></o:p></span></b></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>7<o:p></o:p></span></b></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:5;height:18.5pt'>
          <td width=111 valign=top style='width:83.25pt;border-top:none;
          border-left:solid lightgrey 1.0pt;border-bottom:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-top-alt:solid lightgrey .25pt;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-right-alt:solid lightgrey .25pt;padding:4.0pt 2.0pt 4.0pt 2.0pt;
          height:18.5pt;min-width: 29.37mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>A-2018-0009<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-08-09<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.64mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-11-22<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Rotherman<o:p></o:p></span></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 84.93mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>This is summary text<o:p></o:p></span></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 38.10mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Assist<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-08-09<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 21.96mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:18.5pt;min-width: 17.46mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>3<o:p></o:p></span></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:6;height:18.5pt'>
          <td width=111 valign=top style='width:83.25pt;border:solid lightgrey 1.0pt;
          border-bottom:none;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 29.37mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>A-2018-0013<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2019-01-23<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.64mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2019-02-22<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Sunshine<o:p></o:p></span></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 84.93mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>all correspondence<o:p></o:p></span></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 38.10mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>StatusChange<br>
          Status Change Consult - Approval<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2019-01-23<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 21.96mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:18.5pt;min-width: 17.46mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>4<o:p></o:p></span></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:7;height:15.0pt'>
          <td width=1194 colspan=11 valign=top style='width:895.6pt;border:
          solid lightgrey 1.0pt;mso-border-alt:solid lightgrey .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Gagliardi, Dan
          Total<o:p></o:p></span></i></b></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-alt:solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2<o:p></o:p></span></b></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-alt:solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>7<o:p></o:p></span></b></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          none;mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:
          solid lightgrey .25pt;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          none;mso-border-top-alt:solid lightgrey .25pt;mso-border-bottom-alt:
          solid lightgrey .25pt;background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          none;mso-border-top-alt:solid lightgrey .25pt;mso-border-bottom-alt:
          solid lightgrey .25pt;background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          none;mso-border-top-alt:solid lightgrey .25pt;mso-border-bottom-alt:
          solid lightgrey .25pt;background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-top-alt:solid lightgrey .25pt;mso-border-bottom-alt:
          solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          none;mso-border-top-alt:solid lightgrey .25pt;mso-border-bottom-alt:
          solid lightgrey .25pt;background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          none;mso-border-top-alt:solid lightgrey .25pt;mso-border-bottom-alt:
          solid lightgrey .25pt;background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-top-alt:solid lightgrey .25pt;mso-border-bottom-alt:
          solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:8;height:16.5pt'>
          <td width=111 valign=top style='width:83.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal align=right style='text-align:right'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal align=right style='text-align:right'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:9;height:15.1pt'>
          <td width=347 colspan=4 valign=top style='width:260.3pt;border:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Lyon, Melissa<o:p></o:p></span></b></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey 1.0pt;
          mso-border-alt:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:lightblue;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:10;height:15.1pt'>
          <td width=347 colspan=4 valign=top style='width:260.3pt;border:none;
          border-bottom:solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Access Requests (A)<o:p></o:p></span></b></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          mso-border-right-alt:solid lightgrey .25pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>7<o:p></o:p></span></b></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:none;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1919<o:p></o:p></span></b></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border:none;border-bottom:
          solid lightgrey 1.0pt;mso-border-bottom-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:11;height:18.5pt'>
          <td width=111 valign=top style='width:83.25pt;border-top:none;
          border-left:solid lightgrey 1.0pt;border-bottom:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-top-alt:solid lightgrey .25pt;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-right-alt:solid lightgrey .25pt;padding:4.0pt 2.0pt 4.0pt 2.0pt;
          height:18.5pt;min-width: 29.37mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>A-2017-00006<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-03-23<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.64mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-06-12<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Rotherman<o:p></o:p></span></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 84.93mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All briefing materials and memos prepared for the CEO of
          Sustainable Development Technology Canada in February 2018.<o:p></o:p></span></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 38.10mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Approval<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-14<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-30<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 21.96mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:18.5pt;min-width: 17.46mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>480<o:p></o:p></span></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Approval<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-14<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-30<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:12;height:18.5pt'>
          <td width=111 valign=top style='width:83.25pt;border:solid lightgrey 1.0pt;
          border-bottom:none;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 29.37mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>A-2018-0001<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-04-10<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.64mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-07-09<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>McDaniel<o:p></o:p></span></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 84.93mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All agreements, applications, contractual documents, or
          records relating to any agreement, application or contract between
          SDTC and Enerkem.<o:p></o:p></span></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 38.10mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>9C Extension<br>
          Notice of extension sent<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-10<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 21.96mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:18.5pt;min-width: 17.46mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1335<o:p></o:p></span></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:13;height:18.5pt'>
          <td width=111 valign=top style='width:83.25pt;border:solid lightgrey 1.0pt;
          border-bottom:none;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 
29.37mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>A-2018-0005<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-26<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.64mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-06-25<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Rotherman<o:p></o:p></span></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 84.93mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 38.10mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>InfoComplete<br>
          - 2018-05-26<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-26<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 21.96mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:18.5pt;min-width: 17.46mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>3<o:p></o:p></span></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:14;height:18.5pt'>
          <td width=111 valign=top style='width:83.25pt;border:solid lightgrey 1.0pt;
          border-bottom:none;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 29.37mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>A-2018-0006<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.64mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>salamander<o:p></o:p></span></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 84.93mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 38.10mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 21.96mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:18.5pt;min-width: 17.46mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:15;height:18.5pt'>
          <td width=111 valign=top style='width:83.25pt;border:solid lightgrey 1.0pt;
          border-bottom:none;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 29.37mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>A-2018-0007<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-28<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.64mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-06-27<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 84.93mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 38.10mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>InfoComplete<br>
          - 2018-05-28<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-28<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 21.96mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:18.5pt;min-width: 17.46mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>87<o:p></o:p></span></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:16;height:18.5pt'>
          <td width=111 valign=top style='width:83.25pt;border:solid lightgrey 1.0pt;
          border-bottom:none;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 29.37mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>A-2018-0008<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-06-04<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.64mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-07-04<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Sallinger<o:p></o:p></span></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 84.93mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 
38.10mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Retrieval<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-08-06<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-08-20<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 21.96mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:18.5pt;min-width: 17.46mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>7<o:p></o:p></span></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Retrieval<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-08-06<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-08-20<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:17;height:18.5pt'>
          <td width=111 valign=top style='width:83.25pt;border-top:solid white 1.0pt;
          border-left:solid lightgrey 1.0pt;border-bottom:none;border-right:
          solid lightgrey 1.0pt;mso-border-top-alt:solid white .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 29.37mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid white 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid white .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid white 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid white .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.64mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border-top:solid white 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid white .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.90mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border-top:solid white 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid white .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 84.93mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border-top:solid white 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid white .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 38.10mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid white 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid white .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid white 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid white .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid white 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid white .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 20.11mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid white 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid white .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid white 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid white .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 21.96mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border-top:solid white 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid white .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:18.5pt;min-width: 17.46mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid white 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid white .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt;min-width: 18.79mm'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Retrieval<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-08-06<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-08-20<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:none;border-right:solid lightgrey 1.0pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:solid lightgrey .25pt;
          mso-border-left-alt:solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          padding:4.0pt 2.0pt 4.0pt 2.0pt;height:18.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:18;height:15.0pt'>
          <td width=1194 colspan=11 valign=top style='width:895.6pt;border:
          solid lightgrey 1.0pt;mso-border-alt:solid lightgrey .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><i><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Lyon, Melissa
          Total<o:p></o:p></span></i></b></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-alt:solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>7<o:p></o:p></span></b></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-alt:solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.0pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><b><span style='font-size:9.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1919<o:p></o:p></span></b></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          none;mso-border-left-alt:solid lightgrey .25pt;mso-border-top-alt:
          solid lightgrey .25pt;mso-border-left-alt:solid lightgrey .25pt;
          mso-border-bottom-alt:solid lightgrey .25pt;background:gainsboro;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          none;mso-border-top-alt:solid lightgrey .25pt;mso-border-bottom-alt:
          solid lightgrey .25pt;background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          none;mso-border-top-alt:solid lightgrey .25pt;mso-border-bottom-alt:
          solid lightgrey .25pt;background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          none;mso-border-top-alt:solid lightgrey .25pt;mso-border-bottom-alt:
          solid lightgrey .25pt;background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-top-alt:solid lightgrey .25pt;mso-border-bottom-alt:
          solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          none;mso-border-top-alt:solid lightgrey .25pt;mso-border-bottom-alt:
          solid lightgrey .25pt;background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:solid lightgrey 1.0pt;
          border-left:none;border-bottom:solid lightgrey 1.0pt;border-right:
          none;mso-border-top-alt:solid lightgrey .25pt;mso-border-bottom-alt:
          solid lightgrey .25pt;background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border:solid lightgrey 1.0pt;
          border-left:none;mso-border-top-alt:solid lightgrey .25pt;mso-border-bottom-alt:
          solid lightgrey .25pt;mso-border-right-alt:solid lightgrey .25pt;
          background:gainsboro;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:19;mso-yfti-lastrow:yes;height:16.5pt'>
          <td width=111 valign=top style='width:83.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=79 valign=top style='width:59.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal align=right style='text-align:right'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=321 valign=top style='width:240.8pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal align=right style='text-align:right'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=144 valign=top style='width:1.5in;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=66 valign=top style='width:49.5pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=92 valign=top style='width:69.0pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=83 valign=top style='width:62.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=78 valign=top style='width:58.5pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=123 valign=top style='width:92.25pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=125 valign=top style='width:93.75pt;border:none;mso-border-top-alt:
          solid lightgrey .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:16.5pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1440 style='width:15.0in;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in' cols=5>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:7.9pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:7.9pt'></td>
      <td width=3 style='width:2.25pt;padding:0in 0in 0in 0in;height:7.9pt;
      min-width: 0.79mm'></td>
      <td width=263 style='width:197.3pt;padding:0in 0in 0in 0in;height:7.9pt;
      min-width: 69.59mm'></td>
      <td width=1440 style='width:15.0in;padding:0in 0in 0in 0in;height:7.9pt;
      min-width: 414.21mm'></td>
      <td width=265 style='width:198.4pt;padding:0in 0in 0in 0in;height:7.9pt;
      min-width: 69.98mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
      <td width=1440 colspan=3 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
      min-width: 484.58mm'></td>
      <td width=265 rowspan=2 valign=top style='width:198.4pt;padding:0in 0in 0in 0in;
      min-width: 69.98mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:20.25pt'>
        <td width=263 valign=top style='width:197.3pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:20.25pt;min-width: 69.59mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Page 2 of 2<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes;height:20.25pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:20.25pt'></td>
      <td width=3 valign=top style='width:2.25pt;padding:0in 0in 0in 0in;
      height:20.25pt;min-width: 0.79mm'></td>
      <td width=263 valign=top style='width:197.3pt;padding:0in 0in 0in 0in;
      height:20.25pt;min-width: 69.59mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:20.25pt'>
        <td width=263 valign=top style='width:197.3pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:20.25pt;min-width: 69.59mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Axcess-1<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=1440 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
      height:20.25pt;min-width: 414.21mm'></td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>

<p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'><o:p>&nbsp;</o:p></span></p>

</div>

</div>

        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

//import { mapGetters, mapState} from "vuex";
import axcessApi from "@/plugins/axcessApi";
export default {
  props:['organisation','dateFrom','dateTo','reportId'],
  created(){
    this.generateReport();
  },
  methods: {
    generateReport() {
      let data = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        reportId: this.reportId

      }
      const response = axcessApi.postRequest("/report/tbs",data);
      response.then(output => {
        console.log(output);
      })
    },
    selected() {
    }
  }
};
</script>