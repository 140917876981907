<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col  cols="12" sm="12" md="12">

        


<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=780 style='width:585.05pt;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:9.0pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:9.0pt'></td>
        <td width=12 style='width:9.0pt;padding:0in 0in 0in 0in;height:9.0pt;
        min-width: 3.18mm'></td>
        <td width=768 style='width:576.05pt;padding:0in 0in 0in 0in;height:
        9.0pt;min-width: 203.20mm'></td>
       </tr>
       <tr style='mso-yfti-irow:1;height:27.0pt'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
        height:27.0pt'></td>
        <td width=12 valign=top style='width:9.0pt;padding:0in 0in 0in 0in;
        height:27.0pt;min-width: 3.18mm'></td>
        <td width=768 valign=top style='width:576.05pt;padding:0in 0in 0in 0in;
        height:27.0pt;min-width: 203.20mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes;height:27.0pt'>
          <td width=756 valign=top style='width:567.1pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:27.0pt;min-width: 200.03mm'>
          <p class=MsoNormal align=center style='text-align:center'><b><span
          style='font-size:14.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Snapshot Report<o:p></o:p></span></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:2;height:.25in'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
        height:.25in'></td>
        <td width=12 valign=top style='width:9.0pt;padding:0in 0in 0in 0in;
        height:.25in;min-width: 3.18mm'></td>
        <td width=768 valign=top style='width:576.05pt;padding:0in 0in 0in 0in;
        height:.25in;min-width: 203.20mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes;height:.25in'>
          <td width=756 valign=top style='width:567.1pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in;min-width: 200.03mm'>
          <p class=MsoNormal align=center style='text-align:center'><b><span
          style='font-size:14.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>October 21, 2011 to October 14, 2020<o:p></o:p></span></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:3;height:10.9pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:10.9pt'></td>
        <td width=780 colspan=2 style='width:585.1pt;padding:0in 0in 0in 0in;
        height:10.9pt;min-width: 206.38mm'></td>
       </tr>
       <tr style='mso-yfti-irow:4'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=12 valign=top style='width:9.0pt;padding:0in 0in 0in 0in;
        min-width: 3.18mm'></td>
        <td width=768 valign=top style='width:576.05pt;padding:0in 0in 0in 0in;
        min-width: 203.20mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=756 style='width:567.1pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 200.03mm' cols=5>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=95 style='width:70.9pt;padding:0in 0in 0in 0in;min-width: 25.00mm'></td>
          <td width=95 style='width:70.9pt;padding:0in 0in 0in 0in;min-width: 25.00mm'></td>
          <td width=95 style='width:70.9pt;padding:0in 0in 0in 0in;min-width: 25.00mm'></td>
          <td width=95 style='width:70.9pt;padding:0in 0in 0in 0in;min-width: 25.00mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:.25in'>
          <td width=378 style='width:283.55pt;border:solid black 1.0pt;
          background:steelblue;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>Access to
          Information Requests<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border:solid black 1.0pt;border-left:
          none;mso-border-left-alt:solid black 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>Total<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border:solid black 1.0pt;border-left:
          none;mso-border-left-alt:solid black 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>On Time<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border:solid black 1.0pt;border-left:
          none;mso-border-left-alt:solid black 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>Late<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border:solid black 1.0pt;border-left:
          none;mso-border-left-alt:solid black 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>Re-Release<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:.25in'>
          <td width=378 style='width:283.55pt;border:solid black 1.0pt;
          border-top:none;mso-border-top-alt:solid black 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Access Requests carried over from
          2019/2020<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          background:gainsboro;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>8<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          background:gainsboro;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          background:gainsboro;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>8<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          background:gainsboro;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:3;height:.25in'>
          <td width=378 style='width:283.55pt;border:solid black 1.0pt;
          border-top:none;mso-border-top-alt:solid black 1.0pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Access Requests received from Oct
          21,2011 to Oct 14,2020<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>13<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:4;height:.25in'>
          <td width=378 style='width:283.55pt;border-top:none;border-left:solid black 1.0pt;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Access Requests closed from Oct
          21,2011 to Oct 14,2020<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>5<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>5<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:5;height:.25in'>
          <td width=378 style='width:283.55pt;border:solid black 1.0pt;
          border-top:none;mso-border-top-alt:solid black 2.25pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><b><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Balance Active Requests as of Oct
          14,2020<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>8<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>8<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:6;height:.25in'>
          <td width=378 style='width:283.55pt;border:solid black 1.0pt;
          border-top:none;mso-border-top-alt:solid black 1.0pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><b><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Compliance Rate - NEW Business<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>38.46%<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:7;mso-yfti-lastrow:yes;height:.25in'>
          <td width=378 style='width:283.55pt;border:solid black 1.0pt;
          border-top:none;mso-border-top-alt:solid black 1.0pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><b><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Compliance Rate - NEW+PREVIOUS
          Business<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>38.46%<o:p></o:p></span></p>
          </td>
          <td width=95 valign=top style='width:70.9pt;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:5;height:24.0pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:24.0pt'></td>
        <td width=780 colspan=2 style='width:585.1pt;padding:0in 0in 0in 0in;
        height:24.0pt;min-width: 206.38mm'></td>
       </tr>
       <tr style='mso-yfti-irow:6'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=12 valign=top style='width:9.0pt;padding:0in 0in 0in 0in;
        min-width: 3.18mm'></td>
        <td width=768 valign=top style='width:576.05pt;padding:0in 0in 0in 0in;
        min-width: 203.20mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=756 style='width:567.1pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 200.03mm' cols=4>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=95 style='width:70.9pt;padding:0in 0in 0in 0in;min-width: 25.00mm'></td>
          <td width=95 style='width:70.9pt;padding:0in 0in 0in 0in;min-width: 25.00mm'></td>
          <td width=95 style='width:70.9pt;padding:0in 0in 0in 0in;min-width: 25.00mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:31.5pt'>
          <td width=473 style='width:354.45pt;border:solid black 1.0pt;
          background:steelblue;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:31.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>Consultation
          Requests<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border:solid black 1.0pt;border-left:
          none;mso-border-left-alt:solid black 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:31.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>Total<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border:solid black 1.0pt;border-left:
          none;mso-border-left-alt:solid black 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:31.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>PCO - Old Cab
          Docs<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border:solid black 1.0pt;border-left:
          none;mso-border-left-alt:solid black 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:31.5pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>Other<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:.25in'>
          <td width=473 style='width:354.45pt;border:solid black 1.0pt;
          border-top:none;mso-border-top-alt:solid black 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Consultation Requests carried over
          from 2019/2020<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          background:gainsboro;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          background:gainsboro;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          background:gainsboro;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:3;height:.25in'>
          <td width=473 style='width:354.45pt;border:solid black 1.0pt;
          border-top:none;mso-border-top-alt:solid black 1.0pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Consultation Requests received from
          Oct 21,2011 to Oct 14,2020<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>5<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>5<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:4;height:.25in'>
          <td width=473 style='width:354.45pt;border-top:none;border-left:solid black 1.0pt;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Consultation Requests closed from Oct
          21,2011 to Oct 14,2020<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>5<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>5<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:5;mso-yfti-lastrow:yes;height:.25in'>
          <td width=473 style='width:354.45pt;border:solid black 1.0pt;
          border-top:none;mso-border-top-alt:solid black 2.25pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><b><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Balance Active Consultation Requests
          as of Oct 14,2020<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:7;height:14.65pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:14.65pt'></td>
        <td width=780 colspan=2 style='width:585.1pt;padding:0in 0in 0in 0in;
        height:14.65pt;min-width: 206.38mm'></td>
       </tr>
       <tr style='mso-yfti-irow:8'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=12 valign=top style='width:9.0pt;padding:0in 0in 0in 0in;
        min-width: 3.18mm'></td>
        <td width=768 valign=top style='width:576.05pt;padding:0in 0in 0in 0in;
        min-width: 203.20mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=756 style='width:567.1pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 200.03mm' cols=4>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=95 style='width:70.9pt;padding:0in 0in 0in 0in;min-width: 25.00mm'></td>
          <td width=95 style='width:70.9pt;padding:0in 0in 0in 0in;min-width: 25.00mm'></td>
          <td width=95 style='width:70.9pt;padding:0in 0in 0in 0in;min-width: 25.00mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:.25in'>
          <td width=473 style='width:354.45pt;border:solid black 1.0pt;
          background:steelblue;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>Privacy
          Requests<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border:solid black 1.0pt;border-left:
          none;mso-border-left-alt:solid black 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>Total<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border:solid black 1.0pt;border-left:
          none;mso-border-left-alt:solid black 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>On Time<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border:solid black 1.0pt;border-left:
          none;mso-border-left-alt:solid black 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>Late<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:.25in'>
          <td width=473 style='width:354.45pt;border:solid black 1.0pt;
          border-top:none;mso-border-top-alt:solid black 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Privacy Requests carried over from
          2019/2020<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          background:gainsboro;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          background:gainsboro;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          background:gainsboro;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:3;height:.25in'>
          <td width=473 style='width:354.45pt;border:solid black 1.0pt;
          border-top:none;mso-border-top-alt:solid black 1.0pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Privacy Requests received from Oct
          21,2011 to Oct 14,2020<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:4;height:.25in'>
          <td width=473 style='width:354.45pt;border-top:none;border-left:solid black 1.0pt;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Privacy Requests closed from Oct
          21,2011 to Oct 14,2020<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:5;mso-yfti-lastrow:yes;height:.25in'>
          <td width=473 style='width:354.45pt;border:solid black 1.0pt;
          border-top:none;mso-border-top-alt:solid black 2.25pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><b><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Balance Active Privacy Requests as of
          Oct 14,2020<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:9;height:9.0pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:9.0pt'></td>
        <td width=780 colspan=2 style='width:585.1pt;padding:0in 0in 0in 0in;
        height:9.0pt;min-width: 206.38mm'></td>
       </tr>
       <tr style='mso-yfti-irow:10;mso-yfti-lastrow:yes'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=12 valign=top style='width:9.0pt;padding:0in 0in 0in 0in;
        min-width: 3.18mm'></td>
        <td width=768 valign=top style='width:576.05pt;padding:0in 0in 0in 0in;
        min-width: 203.20mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=756 style='width:567.1pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 200.03mm' cols=4>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=95 style='width:70.9pt;padding:0in 0in 0in 0in;min-width: 25.00mm'></td>
          <td width=95 style='width:70.9pt;padding:0in 0in 0in 0in;min-width: 25.00mm'></td>
          <td width=95 style='width:70.9pt;padding:0in 0in 0in 0in;min-width: 25.00mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:.25in'>
          <td width=473 style='width:354.45pt;border:solid black 1.0pt;
          background:steelblue;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>Complaint
          Requests<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border:solid black 1.0pt;border-left:
          none;mso-border-left-alt:solid black 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>Total<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border:solid black 1.0pt;border-left:
          none;mso-border-left-alt:solid black 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>Access<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border:solid black 1.0pt;border-left:
          none;mso-border-left-alt:solid black 1.0pt;background:steelblue;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:white'>Privacy<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:.25in'>
          <td width=473 style='width:354.45pt;border:solid black 1.0pt;
          border-top:none;mso-border-top-alt:solid black 1.0pt;background:gainsboro;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Complaint Requests carried over from
          2019/2020<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          background:gainsboro;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          background:gainsboro;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          background:gainsboro;padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:3;height:.25in'>
          <td width=473 style='width:354.45pt;border:solid black 1.0pt;
          border-top:none;mso-border-top-alt:solid black 1.0pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Complaint Requests received from Oct
          21,2011 to Oct 14,2020<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:4;height:.25in'>
          <td width=473 style='width:354.45pt;border-top:none;border-left:solid black 1.0pt;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Complaint Requests closed from Oct
          21,2011 to Oct 14,2020<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 2.25pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:5;mso-yfti-lastrow:yes;height:.25in'>
          <td width=473 style='width:354.45pt;border:solid black 1.0pt;
          border-top:none;mso-border-top-alt:solid black 2.25pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:middle'><b><span
          style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Balance Active Complaint Requests as
          of Oct 14,2020<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></b></p>
          </td>
          <td width=95 style='width:70.9pt;border-top:none;border-left:none;
          border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          mso-border-top-alt:solid black 2.25pt;mso-border-left-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 4.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          middle'><b><span style='font-size:10.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=780
     style='width:585.05pt;border-collapse:collapse;mso-yfti-tbllook:1184;
     mso-padding-alt:0in 0in 0in 0in' cols=6>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:4.25pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:4.25pt'></td>
      <td width=180 style='width:135.05pt;padding:0in 0in 0in 0in;height:4.25pt;
      min-width: 47.63mm'></td>
      <td width=144 style='width:1.5in;padding:0in 0in 0in 0in;height:4.25pt;
      min-width: 38.10mm'></td>
      <td width=180 style='width:135.05pt;padding:0in 0in 0in 0in;height:4.25pt;
      min-width: 47.63mm'></td>
      <td width=180 style='width:135.05pt;padding:0in 0in 0in 0in;height:4.25pt;
      min-width: 47.63mm'></td>
      <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;height:4.25pt;
      min-width: 25.40mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;height:.75pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:.75pt'></td>
      <td width=684 colspan=4 valign=top style='width:513.1pt;padding:0in 0in 0in 0in;
      height:.75pt;min-width: 180.98mm'></td>
      <td width=96 rowspan=2 valign=top style='width:1.0in;padding:0in 0in 0in 0in;
      height:.75pt;min-width: 25.40mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 25.40mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><span
        style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
        "Times New Roman";color:black'>1 of 1<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:2;height:17.25pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:17.25pt'></td>
      <td width=180 rowspan=2 valign=top style='width:135.05pt;padding:0in 0in 0in 0in;
      height:17.25pt;min-width: 47.63mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=180 valign=top style='width:135.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 47.63mm'>
        <p class=MsoNormal><b><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
        mso-fareast-font-family:"Times New Roman";color:black'>Snapshot Report<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=144 valign=top style='width:1.5in;padding:0in 0in 0in 0in;
      height:17.25pt;min-width: 38.10mm'></td>
      <td width=180 rowspan=2 valign=top style='width:135.05pt;padding:0in 0in 0in 0in;
      height:17.25pt;min-width: 47.63mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=180 valign=top style='width:135.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 47.63mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
        "Times New Roman";color:black'>2020-10-14<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=180 valign=top style='width:135.05pt;padding:0in 0in 0in 0in;
      height:17.25pt;min-width: 47.63mm'></td>
     </tr>
     <tr style='mso-yfti-irow:3;mso-yfti-lastrow:yes;height:7.0pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:7.0pt'></td>
      <td width=144 style='width:1.5in;padding:0in 0in 0in 0in;height:7.0pt;
      min-width: 38.10mm'></td>
      <td width=276 colspan=2 style='width:207.05pt;padding:0in 0in 0in 0in;
      height:7.0pt;min-width: 73.03mm'></td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>



        </v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

//import { mapGetters, mapState} from "vuex";
import axcessApi from "@/plugins/axcessApi";
export default {
  props:['organisation','dateFrom','dateTo','reportId'],
  created(){
    this.generateReport();
  },
  methods: {
    generateReport() {
      let data = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        reportId: this.reportId

      }
      const response = axcessApi.postRequest("/report/tbs",data);
      response.then(output => {
        console.log(output);
      })
    },
    selected() {
    }
  }
};
</script>